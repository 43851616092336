<template>
	<div>
		<ul>
			<li
				v-for="(item, index) in list_emoji"
				:key="'item_' + index"
				class="mt-10"
			>
				<div class="flex-row items-center">
					<div class="inline-block icon icon-30 mr-10">
						<img
							:src="$request.upload_url(item.member_profle_nft_card_img_url)" alt="추천카르텔"
							@error="$bus.$emit('onErrorProfileImage', $event)"
							class="width-100"
						>
					</div>
					<div class="size-px-14">
						{{  item.nickname }}
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'mafia059'
		, props: ['bbs', 'user']
		, data: function(){
			return {
				program: {
					name: '공감'
					, title: '공감'
					, not_header: true
					, not_footer: true
					, type: 'carte_sub'
				}
				, items_emoji: []
				, icons_emoji: [
					{ code: 'CA01100001', type: 'best', txt: '최고예요'}
					, { code: 'CA01100002', type: 'funny', txt: '웃겨요'}
					, { code: 'CA01100003', type: 'like', txt: '좋아요'}
					, { code: 'CA01100004', type: 'sad', txt: '슬퍼요'}
					, { code: 'CA01100005', type: 'surprise', txt: '놀랐어요'}
					, { code: 'CA01100006', type: 'angry', txt: '화나요'}
				]
			}
		}

		, computed: {
			list_emoji: function(){
				
				return this.items_emoji.filter( (item) => {
					for(let t of this.icons_emoji){
						if(item.sympaty_icon_code == t.code){
							item.type = t.type
						}
					}

					return item
				})
			}
		}
		, methods: {

			getEmojiList: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_emoji_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						}
						, type: true
					})

					if(result.success){
						this.items_emoji = result.data.sympaty_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.getEmojiList();
		}
	}
</script>