<template>
	<div
		v-if="is_view"
		class="position-fixed-full"
		style="z-index: 9999"
	>
		<PopupSelect
			v-if="!is_select"
			@cancel="$emit('cancel')"
			style="z-index: 1004;"
		>
			<template
				v-slot:title
			>
				<h3 class="size-px-20 inline-block">게시글</h3>
				<h6
					v-if="is_mine"
					class="ml-10 inline-block"
				>내가 쓴 글</h6>
			</template>
			<template
				v-slot:list
			>
				<li
					v-if="is_on_move"
					@click="onMove"
				><button>{{ $language.title.change_location_bbs }}</button></li>

				<li
					v-if="is_modify"
					@click="onModify"
				><button>{{  $language.word.modify }}</button></li>

				<li
					v-if="is_modify"
					@click="onConfirm('delete_bbs')"
				><button>{{  $language.word.delete }}</button></li>

				<li
					v-if="!is_mine"
					@click="onSelect"
				><button>{{ $language.title.report_bbs }}</button></li>

				<template
					v-if="!is_mine"
				>
					<template
						v-if="item_writer.member_number"
					>
						<li
							v-if="!is_friend"
						>
							<div
								@click="onConfirm('add_friend')"
								class="flex-row justify-space-between"
							>
								<span class="" style="color: #3e47b7">{{  item_writer.nickname }}</span>
								<button class="" style="color: #3e47b7">{{  $language.word.friend_add }}</button>
							</div>
						</li>
						<li
							v-if="is_friend"
						>
							<div
								@click="onConfirm('cancel_friend')"
								class="flex-row justify-space-between"
							>
								<span class="" style="color:#dc505c">{{  item_writer.nickname }}</span>
								<button class="color-red" style="color:#dc505c">{{  $language.word.friend_cancel }}</button>
							</div>
						</li>
						<li
							v-if="is_black_list"
						>
							<div
								@click="onConfirm('block_cancel')"
								class="flex-row justify-space-between"
							>
								<span class="" style="color: #3e47b7">{{  item_writer.nickname }}</span>
								<button class="" style="color: #3e47b7">{{  $language.word.block_cancel }}</button>
							</div>
						</li>

						<li
							v-else
						>
							<div
								@click="onConfirm('block_account')"
								class="flex-row justify-space-between"
							>
								<span class="" style="color:#dc505c">{{  item_writer.nickname }}</span>
								<button class="" style="color:#dc505c">{{  $language.word.block }}</button>
							</div>
						</li>
					</template>
					<li
						v-else
					>
						<div
							class="line_clamp1 col_red clear"
						>
							<span class="btn_left">탈퇴 또는 휴면 계정입니다</span>
						</div>
					</li>
				</template>
			</template>
		</PopupSelect>

		<PopupConfirm
			v-if="is_confirm"

			@click="doPost"
			@cancel="is_confirm = false"
			style="z-index: 1005"
		>
			<template
				v-slot:title
			>{{ $language.confirm[confirm_type].title }}</template>
			<template
				v-slot:main-txt
			>{{  $language.confirm[confirm_type].main }}</template>
			<template
				v-slot:sub-txt
			>
				<template
					v-if="confirm_type == 'add_friend'"
				>
					<input v-model="is_notify" type="checkbox" id="mdChk">
					<label for="mdChk"> {{  $language.confirm[confirm_type].sub }}</label>
				</template>
				<template
					v-else
				>{{ $language.confirm[confirm_type].sub }} <template v-if="confirm_type=='report_bbs'">{{item_reason.name}}</template></template>
			</template>
		</PopupConfirm>

		<PopupSelect
			v-if="is_move"
			@cancel="is_move = false"
			style="z-index: 1005;"
		>
			<template
				v-slot:title
			>게시판 선택</template>
			<template
				v-slot:list
			>
				<li
					v-for="(item, index) in list_board"
					:key="'item_' + index"
					@click="postMove(item)"
					class="ptb-10 cursor-pointer"
				>{{ item.board_name }}</li>
			</template>
		</PopupSelect>

		<PopupSelect
			v-if="is_select"
			@cancel="is_select = false"
			style="z-index: 1005"
		>
			<template
				v-slot:title
			>신고 사유</template>
			<template
				v-slot:list
			>
				<li
					v-for="(item, index) in reason"
					:key="'item_' + index"
					class="cursor-pointer ptb-10"
					@click="setReason(item)"
				>{{ item.name }}</li>
			</template>
		</PopupSelect>
	</div>
</template>

<script>
import PopupConfirm from '@/view/Layout/PopupConfirm'
import PopupSelect from "@/view/Layout/PopupSelect";
export default {
	name: 'mafia064'
	, props: ['item_bbs', 'user']
	, components: {PopupSelect, PopupConfirm}
	, data: function(){
		return {
			confirm_type: ''
			, is_view: false
			, is_confirm: false
			, is_delete: false
			, is_block: false
			, is_report: false
			, item_writer: {}
			, item_friend: {}
			, item_black: {}
			, is_notify: true
			, is_select: false
			, is_move: false
			, item_reason: {}
			, reason: [
				{ code: 'CA01200001', name: '부적절한 홍보'}
				, { code: 'CA01200002', name: '혐오 발언'}
				, { code: 'CA01200003', name: '음란성 또는 부적합한 내용'}
				, { code: 'CA01200004', name: '같은내용 반복'}
				, { code: 'CA01200005', name: '저작권 침해'}
				, { code: 'CA01200006', name: '거짓 정보 및 선동'}
			]
			, items_board_list: []
			, item_permission: {

			}
			, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.item_bbs.board_number
			, item_board: {
				board_authority: {
					reg_fg: ''
				}
			}
		}
	}
	, computed: {
		is_friend: function(){
			let t = false

			if(this.item_writer.member_number && this.item_writer.member_friend_fg == 'Y'){
				t = true
			}
			return t
		}
		, is_mine: function(){
			let t = false
			if(this.item_writer.member_number && this.user.member_number == this.item_writer.member_number){
				t = true
			}
			return t
		}
		, is_modify: function(){
			let t = false

			switch(this.item_permission.cartl_member_grade_chg_info.chg_cartl_member_div_code){
				case 'CA02500001':
					t = true
					break
				case 'CA02500002':
					if(this.item_board.board_authority.admin_authority_fg == 'Y' || this.item_board.board_authority.change_fg == 'Y' && (this.item_writer.member_number && this.item_writer.member_number == this.user.member_number)){
						t = true
					}
					break
				case 'CA02500003':
					if(this.item_board.board_authority.change_fg == 'Y' && (this.item_writer.member_number && this.item_writer.member_number == this.user.member_number)){
						t = true
					}
					break
			}

			return t
		}
		, is_on_move: function(){

			let t = false

			if(this.item_board.board_type_code == 'CA00700003'){
				if(this.item_board.board_authority.admin_authority_fg == 'Y'){
					t = true
				}
			}

			return t
		}
		, is_black_list: function(){
			let t = false

			if(this.item_writer.member_number && this.item_writer.blok_member_fg == 'Y'){
				t = true
			}
			return t
		}
		, list_board: function(){
			return this.items_board_list.filter(function(item){
				if(item.board_type_code == 'CA00700003' ){
					return item
				}
			})
		}
	}
	, methods: {

		onConfirm: function(type){
			this.confirm_type = type
			this.is_confirm = true
			this.is_select = false
		}
		, onBlock: function(){
			this.is_block = true
		}
		, onDelete: function(){
			this.is_delete = true
		}
		, onReport: function(){
			this.is_report = true
		}
		, getWriter: async function(){

			try {

				if(!this.item_bbs.member_number && !this.item_bbs.post_member_number){
					return false
				}

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_member_info
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.item_bbs.member_number ? this.item_bbs.member_number : this.item_bbs.post_member_number
					}
					, type: true
				})

				if(result.success){
					this.item_writer = result.data.MmbBasicInfo
					this.$log.console('this.item_writer', this.item_writer)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
				this.is_view = true
			}
		}
		, postFriend: async function(){
			try {
				let url = this.$api_url.api_path.post_friend_add
				if(this.is_friend){
					url = this.$api_url.api_path.post_friend_cancel
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, friend_member_number: this.item_writer.member_number
						, note_fg: this.is_notify ? 'Y' : 'N'
					}
					, type: true
				})

				if(result.success){
					await this.getWriter()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}

		, postDeleteBbs: async function(){
			try {

				let url = this.$api_url.api_path.post_delete_bbs
				if(this.item_bbs.board_type_code == 'CA00700001'){
					url = this.$api_url.api_path.post_delete_notice
				}else if(this.item_bbs.board_type_code == 'CA00700002'){
					url = this.$api_url.api_path.post_delete_cheer
				}

				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx
						, board_number: this.item_bbs.board_number
						, bulletin_number: this.item_bbs.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.$emit('deleteBbs')
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_delete = false
				this.is_confirm = false
				this.is_select = false
			}
		}

		, postBlockCancel: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_black_cancel
					, data: {
						member_number: this.user.member_number
						, blok_member_number: this.item_writer.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWriter()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postBlockMember: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_black_add
					, data: {
						member_number: this.user.member_number
						, blok_member_number: this.item_writer.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWriter()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, postReportBbs: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_report_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx
						, board_number: this.item_bbs.board_number
						, bulletin_number: this.item_bbs.bulletin_number
						, post_declaration_reason_code: this.item_reason.code
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_delete = false
				this.is_confirm = false
			}
		}
		, doPost: function(){
			switch (this.confirm_type){
				case 'add_friend':
					this.postFriend()
					break;
				case 'cancel_friend':
					this.postFriend()
					break;
				case 'block_account':
					this.postBlockMember()
					break;
				case 'block_cancel':
					this.postBlockCancel()
					break;
				case 'delete_bbs':
					this.postDeleteBbs()
					break;
				case 'report_bbs':
					this.postReportBbs()
					break;
				default:
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_confirm_type})
					break;
			}
		}
		, getMyBlackList: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_black_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.item_black = result.data[0]
				}else{
					this.item_black = {}
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardList: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx
					}
					, type: true
				})

				if (result.success) {
					this.items_board_list = result.data.board_list
					this.$bus.$emit('setBoardConfigSort', this.items_board_list)
					this.is_move = true
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardConfig: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx
						, board_number: this.board_number
					}
					, type: true
				})

				if (result.success) {
					this.item_board = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMove: function(){
			this.getBoardList()
		}
		, onSelect: function(){
			this.is_select = true
		}
		, setReason: function(item){
			this.item_reason = item
			this.onConfirm('report_bbs')
		}
		, mount: async function(){

			if(this.item_bbs.post_member_state_code == 'MB00500005'){
				// await this.getWriter()
			}
			await this.getBoardConfig()
			await this.getPermission()
			await this.getWriter()
			// await this.getMyFriend()
			// await this.getMyBlackList()
		}
		, postMove: async function(item){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_board_move
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx
						, board_number: this.board_number
						, bulletin_number: this.item_bbs.bulletin_number
						, moving_board_number: item.board_number
					}
					, type: true
				})

				if(result.success){

					// this.$bus.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, b_code: item.board_type_code, b_id: item.board_number, bbs_id: result.data.bulletin_number}})
					this.$emit('move', result.data)
				}else{
					throw result.message
				}
			}catch (e){
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, onModify:  function(){
			/*
			let params = {
				idx: this.$route.params.idx
				, code: this.item_bbs.board_type_code ? this.item_bbs.board_type_code : this.$route.params.code
				, b_id: this.item_bbs.board_number
				, bbs_id: this.item_bbs.bulletin_number
				,  from: this.$route.name
			}
			//this.$log.console('item_bbs', this.item_bbs)
			//this.$log.console('params', params)
			// this.$bus.$emit('to', { name: 'mafia1272', params: params })
			*/


			this.$log.console('this.item_bbs', this.item_bbs)
			this.$bus.$emit('to', { name: 'mafia1272', params: { idx: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx, code: this.item_bbs.board_type_code, b_id: this.item_bbs.board_number, bbs_id: this.item_bbs.bulletin_number}})
		}
		, getPermission: async function(){
			try{
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.item_bbs.cartl_number ? this.item_bbs.cartl_number : this.$route.params.idx
					}
					, type: true
				})
				if(result.success){
					this.item_permission = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,async created() {
		this.$log.console('this.item_bbs', this.item_bbs)
		await this.mount()
	}
}
</script>

<style>
	.box-select li {
		padding: 10px 0;
	}
</style>